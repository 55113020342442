<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-row v-if="type === 'PROCESSING'" wrap no-gutters>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.general }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempMaxProcessingDurationInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .maxProcessingDuration
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="maxProcessingDuration-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitPDur"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="maxProcessingDuration-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempInstanceHeartbeatInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .instanceHeartbeatInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="instanceHeartbeatInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitIHB"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="instanceHeartbeatInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempSyncSettingsDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .syncSettingsDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="syncSettingsDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitSSD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="syncSettingsDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempUserRegisterTokenTtlInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .userRegisterTokenTtlInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="userRegisterTokenTtlInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitURTT"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="userRegisterTokenTtlInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="
              values.systemSchedulerPoolSize
            "
            outlined
            dense
            :label="
              $lang.labels
                .systemSchedulerPoolSize
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="systemSchedulerPoolSize-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.credentials }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempInactiveTtlInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .inactiveTtlInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="inactiveTtlInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitITT"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="inactiveTtlInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempSyncDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .syncDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="syncDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitSD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="syncDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.groovyScript }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempGroovyScriptInactiveTtlInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .inactiveTtlInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="groovyScriptInactiveTtlInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitGSITT"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="groovyScriptInactiveTtlInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempGroovyScriptCleanUpDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .cleanUpDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="groovyScriptCleanUpDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitGSCUD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="groovyScriptCleanUpDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.internalJob }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempInternalJobTimeoutInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .timeoutInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="timeoutInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitIJT"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="timeoutInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempInternalJobHeartbeatInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .heartbeatInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="heartbeatInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitIJHB"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="heartbeatInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.library }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempLibrarySyncDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .syncDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="syncDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitLSD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="syncDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.plugin }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempPluginSyncDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .syncDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="syncDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitPSD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="syncDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.processExecution }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempProcessExecutionHeartbeatInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .heartbeatInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="heartbeatInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitPEHB"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="heartbeatInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempProcessExecutionFetchedTtlInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .fetchedTtlInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="fetchedTtlInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitPEFT"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="fetchedTtlInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempProcessExecutionCleanUpLogsDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .cleanUpLogsDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="cleanUpLogsDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitPECUD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="cleanUpLogsDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempProcessExecutionFetchEventsDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .fetchEventsDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="fetchEventsDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitPEFED"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="fetchEventsDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempProcessExecutionCleanUpErrorsDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .cleanUpErrorsDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="cleanUpErrorsDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitPECUED"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="cleanUpErrorsDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempProcessExecutionFixExecutionStateDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .fixExecutionStateDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="fixExecutionStateDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitPEFESD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="fixExecutionStateDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempProcessExecutionExecuteProcessStepChildEventDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .executeProcessStepChildEventDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="executeProcessStepChildEventDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitPEEPSCE"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="executeProcessStepChildEventDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="
              values.processExecution.cleanUpBatchSize
            "
            outlined
            dense
            :label="
              $lang.labels
                .cleanUpBatchSize
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="cleanUpBatchSize-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.restTemplate }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempRestTemplateInactiveTtlInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .inactiveTtlInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="inactiveTtlInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitRTITT"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="inactiveTtlInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempRestTemplateCleanUpDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .cleanUpDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="cleanUpDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitRTCUD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="cleanUpDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.triggerCronSettings }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempTriggerCronSyncDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .syncDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="syncDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitTCS"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="syncDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.triggerMessaging }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempTriggerMessagingSyncDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .syncDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="syncDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitTMS"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="syncDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.triggerRestSettings }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempTriggerRestSyncDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .syncDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="syncDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitTRS"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="syncDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempTriggerRestWaitEventTimeInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .waitEventTimeInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="waitEventTimeInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitTRWET"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="waitEventTimeInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.triggerEventHandler }}
          </h4>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="
              values.triggerEventHandler.eventsCount
            "
            outlined
            dense
            :label="
              $lang.labels
                .eventsCount
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="eventsCount-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempTriggerEventHandlerSyncDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .syncDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="syncDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="4" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitTHS"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="handleDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="8" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempTriggerEventHandlerHandleDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .handleDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="handleDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="4" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitTHH"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="handleDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.storage }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempStorageCleanUpDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .cleanUpDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="cleanUpDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitSCUD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="cleanUpDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.security }}
          </h4>
        </v-col>
        <v-col cols="12" class="mb-2">
          <val
            :can-edit="canEdit"
            :data="values.security.prometheusMetricsWhitelist"
            @dataChanged="values.security.prometheusMetricsWhitelist = $event"
          />
        </v-col>
      </v-row>

      <v-row v-if="type === 'FRONTEND'" wrap no-gutters>
        <v-col cols="12" md="6" class="pr-md-1">
          <v-text-field
            v-model="values.environmentName"
            outlined
            dense
            :label="$lang.labels.environmentName"
            required
            :rules="[(v) => !!v || 'Required!']"
            class="required-asterisk"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pl-md-1">
          <v-select
            v-model="values.defaultLocale"
            :items="availableLanguages"
            :label="$lang.labels.language"
            outlined
            dense
            required
            :readonly="!canEdit"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" class="pr-md-1">
          <v-text-field
            v-model="values.baseUrlRest"
            outlined
            dense
            :label="$lang.labels.baseUrlRest"
            required
            :rules="[(v) => !!v || 'Required!', (v) => validURL(v) || 'Invalid URL']"
            class="required-asterisk"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pl-md-1">
          <v-text-field
            v-model="values.logServerUrl"
            outlined
            dense
            :label="$lang.labels.logServerUrl"
            required
            :rules="[(v) => !!v || 'Required!', (v) => validURL(v) || 'Invalid URL']"
            class="required-asterisk"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pr-md-1">
          <v-text-field
            v-model="values.metricServerUrl"
            outlined
            dense
            :label="$lang.labels.metricServerUrl"
            required
            :rules="[(v) => !!v || 'Required!', (v) => validURL(v) || 'Invalid URL']"
            class="required-asterisk"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pl-md-1">
          <v-text-field
            v-model="values.setPasswordUrl"
            outlined
            dense
            :label="$lang.labels.setPasswordUrl"
            required
            :rules="[(v) => !!v || 'Required!', (v) => validURL(v) || 'Invalid URL']"
            class="required-asterisk"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pr-md-1">
          <v-text-field
            v-model="values.visualisationServerUrl"
            outlined
            dense
            :label="$lang.labels.visualisationServerUrl"
            required
            :rules="[(v) => !!v || 'Required!', (v) => validURL(v) || 'Invalid URL']"
            class="required-asterisk"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="type === 'ADMIN'" no-gutters>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.general }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempInstanceHeartbeatInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .instanceHeartbeatInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="instanceHeartbeatInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitIHB"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="instanceHeartbeatInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempSyncSettingsDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .syncSettingsDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="syncSettingsDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitSSD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="syncSettingsDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempUserRegisterTokenTtlInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .userRegisterTokenTtlInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="userRegisterTokenTtlInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitURTT"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="userRegisterTokenTtlInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="
              values.systemSchedulerPoolSize
            "
            outlined
            dense
            :label="
              $lang.labels
                .systemSchedulerPoolSize
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="systemSchedulerPoolSize-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="
              values.moduleVersion
            "
            outlined
            dense
            :label="
              $lang.labels
                .moduleVersion
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="moduleVersion-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.captcha }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempCaptchaTtlInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .ttlInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="ttlInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitCT"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="ttlInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempCaptchaCleanDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .cleanDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="cleanDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitCCD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="cleanDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <h4>
            {{ $lang.header.history }}
          </h4>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="values.history.cleanEnabled"
            label="Clean enabled"
            data-cy="cleanEnabled"
            :readonly="!canEdit"
          ></v-checkbox>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempHistoryTtlInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .ttlInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="ttlInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitHT"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="ttlInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempHistoryCleanDelayInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .cleanDelayInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="cleanDelayInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitHCD"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="cleanDelayInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.internalJob }}
          </h4>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempInstanceHeartbeatInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .instanceHeartbeatInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="instanceHeartbeatInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitIHB"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="instanceHeartbeatInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="4" lg="6" class="pr-1">
          <v-text-field
            v-model="
              tempInternalJobTimeoutInMSec
            "
            outlined
            dense
            :label="
              $lang.labels
                .timeoutInMSec
            "
            required
            :rules="[
              (v) => !!v || 'Required!',
            ]"
            class="required-asterisk"
            data-cy="timeoutInMSec-value"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="8" lg="6" class="pl-1">
          <v-select
            v-model="timeUnitIJT"
            :items="timeUnits"
            :label="$lang.labels.unit"
            outlined
            dense
            required
            :readonly="!canEdit"
            data-cy="timeoutInMSec-timeUnit"
            style="min-width: 115px"
          ></v-select>
        </v-col>
        <v-col cols="12" class="mb-2">
          <h4>
            {{ $lang.header.security }}
          </h4>
        </v-col>
        <v-col cols="12" class="mb-2">
          <val
            :can-edit="canEdit"
            :data="values.security.prometheusMetricsWhitelist"
            @dataChanged="values.security.prometheusMetricsWhitelist = $event"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { predictBestTimeUnitFromMsValue } from '@/utils/helpers'
import Val from './ValList.vue'

export default {
  name: 'SettingsValues',
  components: {
    Val
  },
  props: {
    type: {
      type: String,
      default: () => ''
    },
    isEdit: {
      type: Boolean,
      default: () => true
    },
    canEdit: {
      type: Boolean,
      default: () => false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      lock: true,
      valid: false,
      values: {
        maxProcessingDurationInMSec: 0,
        baseUrlRest: '',
        defaultLocale: '',
        logServerUrl: '',
        metricServerUrl: '',
        setPasswordUrl: '',
        visualisationServerUrl: '',
        plugin: {
          syncDelayInMSec: ''
        },
        library: {
          syncDelayInMSec: ''
        },
        storage: {
          cleanUpDelayInMSec: ''
        },
        security: {
          prometheusMetricsWhitelist: []
        },
        credentials: {
          syncDelayInMSec: '',
          inactiveTtlInMSec: ''
        },
        internalJob: {
          timeoutInMSec: '',
          heartbeatInMSec: ''
        },
        triggerCron: {
          syncDelayInMSec: ''
        },
        triggerRest: {
          syncDelayInMSec: '',
          waitEventTimeInMSec: ''
        },
        groovyScript: {
          inactiveTtlInMSec: '',
          cleanUpDelayInMSec: ''
        },
        restTemplate: {
          inactiveTtlInMSec: '',
          cleanUpDelayInMSec: ''
        },
        processExecution: {
          heartbeatInMSec: '',
          fetchedTtlInMSec: '',
          cleanUpLogsDelayInMSec: '',
          fetchEventsDelayInMSec: '',
          cleanUpErrorsDelayInMSec: '',
          fixExecutionStateDelayInMSec: '',
          executeProcessStepChildEventDelayInMSec: '',
          cleanUpBatchSize: ''
        },
        triggerMessaging: {
          syncDelayInMSec: ''
        },
        triggerEventHandler: {
          eventsCount: '',
          syncDelayInMSec: '',
          handleDelayInMSec: ''
        },
        instanceHeartbeatInMSec: '',
        syncSettingsDelayInMSec: '',
        systemSchedulerPoolSize: '',
        userRegisterTokenTtlInMSec: '',
        captcha: {
          ttlInMSec: '',
          cleanDelayInMSec: ''
        },
        history: {
          ttlInMSec: '',
          cleanEnabled: '',
          cleanDelayInMSec: ''
        },
        moduleVersion: '',
        environmentName: ''
      },
      isLoadingProcesses: false,
      processes: [],
      searchProcesses: '',
      languages: ['en', 'de', 'bg', 'zh', 'hr', 'cs', 'da', 'nl', 'et', 'fi', 'fr', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
      tempMaxProcessingDurationInMSec: '60',
      timeUnitPDur: 'Sec',
      timeUnits: ['MSec', 'Sec', 'Min', 'Hr', 'Day'],
      tempInstanceHeartbeatInMSec: '60',
      timeUnitIHB: 'Sec',
      tempSyncSettingsDelayInMSec: '60',
      timeUnitSSD: 'Sec',
      tempUserRegisterTokenTtlInMSec: '60',
      timeUnitURTT: 'Sec',
      tempInactiveTtlInMSec: '60',
      timeUnitITT: 'Sec',
      tempSyncDelayInMSec: '60',
      timeUnitSD: 'Sec',
      tempGroovyScriptInactiveTtlInMSec: '60',
      timeUnitGSITT: 'Sec',
      tempGroovyScriptCleanUpDelayInMSec: '60',
      timeUnitGSCUD: 'Sec',
      tempInternalJobTimeoutInMSec: '60',
      timeUnitIJT: 'Sec',
      tempInternalJobHeartbeatInMSec: '60',
      timeUnitIJHB: 'Sec',
      tempLibrarySyncDelayInMSec: '60',
      timeUnitLSD: 'Sec',
      tempPluginSyncDelayInMSec: '60',
      timeUnitPSD: 'Sec',
      tempProcessExecutionHeartbeatInMSec: '60',
      timeUnitPEHB: 'Sec',
      tempProcessExecutionFetchedTtlInMSec: '60',
      timeUnitPEFT: 'Sec',
      tempProcessExecutionCleanUpLogsDelayInMSec: '60',
      timeUnitPECUD: 'Sec',
      tempProcessExecutionFetchEventsDelayInMSec: '60',
      timeUnitPEFED: 'Sec',
      tempProcessExecutionCleanUpErrorsDelayInMSec: '60',
      timeUnitPECUED: 'Sec',
      tempProcessExecutionFixExecutionStateDelayInMSec: '60',
      timeUnitPEFESD: 'Sec',
      tempProcessExecutionExecuteProcessStepChildEventDelayInMSec: '60',
      timeUnitPEEPSCE: 'Sec',
      tempRestTemplateInactiveTtlInMSec: '60',
      timeUnitRTITT: 'Sec',
      tempRestTemplateCleanUpDelayInMSec: '60',
      timeUnitRTCUD: 'Sec',
      tempTriggerCronSyncDelayInMSec: '60',
      timeUnitTCS: 'Sec',
      tempTriggerRestSyncDelayInMSec: '60',
      timeUnitTRS: 'Sec',
      tempTriggerRestWaitEventTimeInMSec: '60',
      timeUnitTRWET: 'Sec',
      tempTriggerMessagingSyncDelayInMSec: '60',
      timeUnitTMS: 'Sec',
      tempTriggerEventHandlerSyncDelayInMSec: '60',
      timeUnitTHS: 'Sec',
      tempTriggerEventHandlerHandleDelayInMSec: '60',
      timeUnitTHH: 'Sec',
      tempStorageCleanUpDelayInMSec: '60',
      timeUnitSCUD: 'Sec',
      tempCaptchaTtlInMSec: '60',
      timeUnitCT: 'Sec',
      tempCaptchaCleanDelayInMSec: '60',
      timeUnitCCD: 'Sec',
      tempHistoryTtlInMSec: '60',
      timeUnitHT: 'Sec',
      tempHistoryCleanDelayInMSec: '60',
      timeUnitHCD: 'Sec'
    }
  },
  computed: {
    availableLanguages() {
      return this.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    }
  },
  watch: {
    values: {
      handler () {
        if (!this.lock) this.$emit('setValues', this.formatValues())
      },
      deep: true
    },
    tempMaxProcessingDurationInMSec: {
      handler () {
        if (!this.lock) this.values.maxProcessingDurationInMSec = this.$options.filters.unitFormatter(
          this.tempMaxProcessingDurationInMSec,
          this.timeUnitPDur,
          'MSec'
        )
      }
    },
    timeUnitPDur: {
      handler () {
        if (!this.lock) this.values.maxProcessingDurationInMSec = this.$options.filters.unitFormatter(
          this.tempMaxProcessingDurationInMSec,
          this.timeUnitPDur,
          'MSec'
        )
      }
    },
    tempInstanceHeartbeatInMSec: {
      handler () {
        if (!this.lock) this.values.instanceHeartbeatInMSec = this.$options.filters.unitFormatter(
          this.tempInstanceHeartbeatInMSec,
          this.timeUnitIHB,
          'MSec'
        )
      }
    },
    timeUnitIHB: {
      handler () {
        if (!this.lock) this.values.instanceHeartbeatInMSec = this.$options.filters.unitFormatter(
          this.tempInstanceHeartbeatInMSec,
          this.timeUnitIHB,
          'MSec'
        )
      }
    },
    tempSyncSettingsDelayInMSec: {
      handler () {
        if (!this.lock) this.values.syncSettingsDelayInMSec = this.$options.filters.unitFormatter(
          this.tempSyncSettingsDelayInMSec,
          this.timeUnitSSD,
          'MSec'
        )
      }
    },
    timeUnitSSD: {
      handler () {
        if (!this.lock) this.values.syncSettingsDelayInMSec = this.$options.filters.unitFormatter(
          this.tempSyncSettingsDelayInMSec,
          this.timeUnitSSD,
          'MSec'
        )
      }
    },
    tempUserRegisterTokenTtlInMSec: {
      handler () {
        if (!this.lock) this.values.userRegisterTokenTtlInMSec = this.$options.filters.unitFormatter(
          this.tempUserRegisterTokenTtlInMSec,
          this.timeUnitURTT,
          'MSec'
        )
      }
    },
    timeUnitURTT: {
      handler () {
        if (!this.lock) this.values.userRegisterTokenTtlInMSec = this.$options.filters.unitFormatter(
          this.tempUserRegisterTokenTtlInMSec,
          this.timeUnitURTT,
          'MSec'
        )
      }
    },
    tempInactiveTtlInMSec: {
      handler () {
        if (!this.lock) this.values.credentials.inactiveTtlInMSec = this.$options.filters.unitFormatter(
          this.tempInactiveTtlInMSec,
          this.timeUnitITT,
          'MSec'
        )
      }
    },
    timeUnitITT: {
      handler () {
        if (!this.lock) this.values.credentials.inactiveTtlInMSec = this.$options.filters.unitFormatter(
          this.tempInactiveTtlInMSec,
          this.timeUnitITT,
          'MSec'
        )
      }
    },
    tempSyncDelayInMSec: {
      handler () {
        if (!this.lock) this.values.credentials.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempSyncDelayInMSec,
          this.timeUnitSD,
          'MSec'
        )
      }
    },
    timeUnitSD: {
      handler () {
        if (!this.lock) this.values.credentials.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempSyncDelayInMSec,
          this.timeUnitSD,
          'MSec'
        )
      }
    },
    tempGroovyScriptInactiveTtlInMSec: {
      handler () {
        if (!this.lock) this.values.groovyScript.inactiveTtlInMSec = this.$options.filters.unitFormatter(
          this.tempGroovyScriptInactiveTtlInMSec,
          this.timeUnitGSITT,
          'MSec'
        )
      }
    },
    timeUnitGSITT: {
      handler () {
        if (!this.lock) this.values.groovyScript.inactiveTtlInMSec = this.$options.filters.unitFormatter(
          this.tempGroovyScriptInactiveTtlInMSec,
          this.timeUnitGSITT,
          'MSec'
        )
      }
    },
    tempGroovyScriptCleanUpDelayInMSec: {
      handler () {
        if (!this.lock) this.values.groovyScript.cleanUpDelayInMSec = this.$options.filters.unitFormatter(
          this.tempGroovyScriptCleanUpDelayInMSec,
          this.timeUnitGSCUD,
          'MSec'
        )
      }
    },
    timeUnitGSCUD: {
      handler () {
        if (!this.lock) this.values.groovyScript.cleanUpDelayInMSec = this.$options.filters.unitFormatter(
          this.tempGroovyScriptCleanUpDelayInMSec,
          this.timeUnitGSCUD,
          'MSec'
        )
      }
    },
    tempInternalJobTimeoutInMSec: {
      handler () {
        if (!this.lock) this.values.internalJob.timeoutInMSec = this.$options.filters.unitFormatter(
          this.tempInternalJobTimeoutInMSec,
          this.timeUnitIJT,
          'MSec'
        )
      }
    },
    timeUnitIJT: {
      handler () {
        if (!this.lock) this.values.internalJob.timeoutInMSec = this.$options.filters.unitFormatter(
          this.tempInternalJobTimeoutInMSec,
          this.timeUnitIJT,
          'MSec'
        )
      }
    },
    tempInternalJobHeartbeatInMSec: {
      handler () {
        if (!this.lock) this.values.internalJob.heartbeatInMSec = this.$options.filters.unitFormatter(
          this.tempInternalJobHeartbeatInMSec,
          this.timeUnitIJHB,
          'MSec'
        )
      }
    },
    timeUnitIJHB: {
      handler () {
        if (!this.lock) this.values.internalJob.heartbeatInMSec = this.$options.filters.unitFormatter(
          this.tempInternalJobHeartbeatInMSec,
          this.timeUnitIJHB,
          'MSec'
        )
      }
    },
    tempLibrarySyncDelayInMSec: {
      handler () {
        if (!this.lock) this.values.library.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempLibrarySyncDelayInMSec,
          this.timeUnitLSD,
          'MSec'
        )
      }
    },
    timeUnitLSD: {
      handler () {
        if (!this.lock) this.values.library.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempLibrarySyncDelayInMSec,
          this.timeUnitLSD,
          'MSec'
        )
      }
    },
    tempPluginSyncDelayInMSec: {
      handler () {
        if (!this.lock) this.values.plugin.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempPluginSyncDelayInMSec,
          this.timeUnitPSD,
          'MSec'
        )
      }
    },
    timeUnitPSD: {
      handler () {
        if (!this.lock) this.values.plugin.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempPluginSyncDelayInMSec,
          this.timeUnitPSD,
          'MSec'
        )
      }
    },
    tempProcessExecutionHeartbeatInMSec: {
      handler () {
        if (!this.lock) this.values.processExecution.heartbeatInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionHeartbeatInMSec,
          this.timeUnitPEHB,
          'MSec'
        )
      }
    },
    timeUnitPEHB: {
      handler () {
        if (!this.lock) this.values.processExecution.heartbeatInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionHeartbeatInMSec,
          this.timeUnitPEHB,
          'MSec'
        )
      }
    },
    tempProcessExecutionFetchedTtlInMSec: {
      handler () {
        if (!this.lock) this.values.processExecution.fetchedTtlInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionFetchedTtlInMSec,
          this.timeUnitPEFT,
          'MSec'
        )
      }
    },
    timeUnitPEFT: {
      handler () {
        if (!this.lock) this.values.processExecution.fetchedTtlInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionFetchedTtlInMSec,
          this.timeUnitPEFT,
          'MSec'
        )
      }
    },
    tempProcessExecutionCleanUpLogsDelayInMSec: {
      handler () {
        if (!this.lock) this.values.processExecution.cleanUpLogsDelayInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionCleanUpLogsDelayInMSec,
          this.timeUnitPECUD,
          'MSec'
        )
      }
    },
    timeUnitPECUD: {
      handler () {
        if (!this.lock) this.values.processExecution.cleanUpLogsDelayInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionCleanUpLogsDelayInMSec,
          this.timeUnitPECUD,
          'MSec'
        )
      }
    },
    tempProcessExecutionFetchEventsDelayInMSec: {
      handler () {
        if (!this.lock) this.values.processExecution.fetchEventsDelayInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionFetchEventsDelayInMSec,
          this.timeUnitPEFED,
          'MSec'
        )
      }
    },
    timeUnitPEFED: {
      handler () {
        if (!this.lock) this.values.processExecution.fetchEventsDelayInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionFetchEventsDelayInMSec,
          this.timeUnitPEFED,
          'MSec'
        )
      }
    },
    tempProcessExecutionCleanUpErrorsDelayInMSec: {
      handler () {
        if (!this.lock) this.values.processExecution.cleanUpErrorsDelayInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionCleanUpErrorsDelayInMSec,
          this.timeUnitPECUED,
          'MSec'
        )
      }
    },
    timeUnitPECUED: {
      handler () {
        if (!this.lock) this.values.processExecution.cleanUpErrorsDelayInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionCleanUpErrorsDelayInMSec,
          this.timeUnitPECUED,
          'MSec'
        )
      }
    },
    tempProcessExecutionFixExecutionStateDelayInMSec: {
      handler () {
        if (!this.lock) this.values.processExecution.fixExecutionStateDelayInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionFixExecutionStateDelayInMSec,
          this.timeUnitPEFESD,
          'MSec'
        )
      }
    },
    timeUnitPEFESD: {
      handler () {
        if (!this.lock) this.values.processExecution.fixExecutionStateDelayInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionFixExecutionStateDelayInMSec,
          this.timeUnitPEFESD,
          'MSec'
        )
      }
    },
    tempProcessExecutionExecuteProcessStepChildEventDelayInMSec: {
      handler () {
        if (!this.lock) this.values.processExecution.executeProcessStepChildEventDelayInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionExecuteProcessStepChildEventDelayInMSec,
          this.timeUnitPEEPSCE,
          'MSec'
        )
      }
    },
    timeUnitPEEPSCE: {
      handler () {
        if (!this.lock) this.values.processExecution.executeProcessStepChildEventDelayInMSec = this.$options.filters.unitFormatter(
          this.tempProcessExecutionExecuteProcessStepChildEventDelayInMSec,
          this.timeUnitPEEPSCE,
          'MSec'
        )
      }
    },
    tempRestTemplateInactiveTtlInMSec: {
      handler () {
        if (!this.lock) this.values.restTemplate.inactiveTtlInMSec = this.$options.filters.unitFormatter(
          this.tempRestTemplateInactiveTtlInMSec,
          this.timeUnitRTITT,
          'MSec'
        )
      }
    },
    timeUnitRTITT: {
      handler () {
        if (!this.lock) this.values.restTemplate.inactiveTtlInMSec = this.$options.filters.unitFormatter(
          this.tempRestTemplateInactiveTtlInMSec,
          this.timeUnitRTITT,
          'MSec'
        )
      }
    },
    tempRestTemplateCleanUpDelayInMSec: {
      handler () {
        if (!this.lock) this.values.restTemplate.cleanUpDelayInMSec = this.$options.filters.unitFormatter(
          this.tempRestTemplateCleanUpDelayInMSec,
          this.timeUnitRTCUD,
          'MSec'
        )
      }
    },
    timeUnitRTCUD: {
      handler () {
        if (!this.lock) this.values.restTemplate.cleanUpDelayInMSec = this.$options.filters.unitFormatter(
          this.tempRestTemplateCleanUpDelayInMSec,
          this.timeUnitRTCUD,
          'MSec'
        )
      }
    },
    tempTriggerCronSyncDelayInMSec: {
      handler () {
        if (!this.lock) this.values.triggerCron.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerCronSyncDelayInMSec,
          this.timeUnitTCS,
          'MSec'
        )
      }
    },
    timeUnitTCS: {
      handler () {
        if (!this.lock) this.values.triggerCron.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerCronSyncDelayInMSec,
          this.timeUnitTCS,
          'MSec'
        )
      }
    },
    tempTriggerRestSyncDelayInMSec: {
      handler () {
        if (!this.lock) this.values.triggerRest.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerRestSyncDelayInMSec,
          this.timeUnitTRS,
          'MSec'
        )
      }
    },
    timeUnitTRS: {
      handler () {
        if (!this.lock) this.values.triggerRest.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerRestSyncDelayInMSec,
          this.timeUnitTRS,
          'MSec'
        )
      }
    },
    tempTriggerRestWaitEventTimeInMSec: {
      handler () {
        if (!this.lock) this.values.triggerRest.waitEventTimeInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerRestWaitEventTimeInMSec,
          this.timeUnitTRWET,
          'MSec'
        )
      }
    },
    timeUnitTRWET: {
      handler () {
        if (!this.lock) this.values.triggerRest.waitEventTimeInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerRestWaitEventTimeInMSec,
          this.timeUnitTRWET,
          'MSec'
        )
      }
    },
    tempTriggerMessagingSyncDelayInMSec: {
      handler () {
        if (!this.lock) this.values.triggerMessaging.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerMessagingSyncDelayInMSec,
          this.timeUnitTMS,
          'MSec'
        )
      }
    },
    timeUnitTMS: {
      handler () {
        if (!this.lock) this.values.triggerMessaging.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerMessagingSyncDelayInMSec,
          this.timeUnitTMS,
          'MSec'
        )
      }
    },
    tempTriggerEventHandlerSyncDelayInMSec: {
      handler () {
        if (!this.lock) this.values.triggerEventHandler.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerEventHandlerSyncDelayInMSec,
          this.timeUnitTHS,
          'MSec'
        )
      }
    },
    timeUnitTHS: {
      handler () {
        if (!this.lock) this.values.triggerEventHandler.syncDelayInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerEventHandlerSyncDelayInMSec,
          this.timeUnitTHS,
          'MSec'
        )
      }
    },
    tempTriggerEventHandlerHandleDelayInMSec: {
      handler () {
        if (!this.lock) this.values.triggerEventHandler.handleDelayInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerEventHandlerHandleDelayInMSec,
          this.timeUnitTHH,
          'MSec'
        )
      }
    },
    timeUnitTHH: {
      handler () {
        if (!this.lock) this.values.triggerEventHandler.handleDelayInMSec = this.$options.filters.unitFormatter(
          this.tempTriggerEventHandlerHandleDelayInMSec,
          this.timeUnitTHH,
          'MSec'
        )
      }
    },
    tempStorageCleanUpDelayInMSec: {
      handler () {
        if (!this.lock) this.values.storage.cleanUpDelayInMSec = this.$options.filters.unitFormatter(
          this.tempStorageCleanUpDelayInMSec,
          this.timeUnitSCUD,
          'MSec'
        )
      }
    },
    timeUnitSCUD: {
      handler () {
        if (!this.lock) this.values.storage.cleanUpDelayInMSec = this.$options.filters.unitFormatter(
          this.tempStorageCleanUpDelayInMSec,
          this.timeUnitSCUD,
          'MSec'
        )
      }
    },
    tempCaptchaTtlInMSec: {
      handler () {
        if (!this.lock) this.values.captcha.ttlInMSec = this.$options.filters.unitFormatter(
          this.tempCaptchaTtlInMSec,
          this.timeUnitCT,
          'MSec'
        )
      }
    },
    timeUnitCT: {
      handler () {
        if (!this.lock) this.values.captcha.ttlInMSec = this.$options.filters.unitFormatter(
          this.tempCaptchaTtlInMSec,
          this.timeUnitCT,
          'MSec'
        )
      }
    },
    tempCaptchaCleanDelayInMSec: {
      handler () {
        if (!this.lock) this.values.captcha.cleanDelayInMSec = this.$options.filters.unitFormatter(
          this.tempCaptchaCleanDelayInMSec,
          this.timeUnitCCD,
          'MSec'
        )
      }
    },
    timeUnitCCD: {
      handler () {
        if (!this.lock) this.values.captcha.cleanDelayInMSec = this.$options.filters.unitFormatter(
          this.tempCaptchaCleanDelayInMSec,
          this.timeUnitCCD,
          'MSec'
        )
      }
    },
    tempHistoryTtlInMSec: {
      handler () {
        if (!this.lock) this.values.history.ttlInMSec = this.$options.filters.unitFormatter(
          this.tempHistoryTtlInMSec,
          this.timeUnitHT,
          'MSec'
        )
      }
    },
    timeUnitHT: {
      handler () {
        if (!this.lock) this.values.history.ttlInMSec = this.$options.filters.unitFormatter(
          this.tempHistoryTtlInMSec,
          this.timeUnitHT,
          'MSec'
        )
      }
    },
    tempHistoryCleanDelayInMSec: {
      handler () {
        if (!this.lock) this.values.history.cleanDelayInMSec = this.$options.filters.unitFormatter(
          this.tempHistoryCleanDelayInMSec,
          this.timeUnitHCD,
          'MSec'
        )
      }
    },
    timeUnitHCD: {
      handler () {
        if (!this.lock) this.values.history.cleanDelayInMSec = this.$options.filters.unitFormatter(
          this.tempHistoryCleanDelayInMSec,
          this.timeUnitHCD,
          'MSec'
        )
      }
    }
  },
  async created() {
    if (this.data) {
      this.values = this.data
    }
    if (this.type === 'PROCESSING') {
      if (this.values.maxProcessingDurationInMSec !== null) {
        const unitResPDur = await predictBestTimeUnitFromMsValue(
          this.values.maxProcessingDurationInMSec
        )

        this.timeUnitPDur = unitResPDur.unit
        this.tempMaxProcessingDurationInMSec = unitResPDur.value
      } else {
        this.tempMaxProcessingDurationInMSec = '60'
        this.timeUnitPDur = 'Sec'
      }

      if (this.values.instanceHeartbeatInMSec !== null) {
        const unitResIHB = await predictBestTimeUnitFromMsValue(
          this.values.instanceHeartbeatInMSec
        )

        this.timeUnitIHB = unitResIHB.unit
        this.tempInstanceHeartbeatInMSec = unitResIHB.value
      } else {
        this.tempInstanceHeartbeatInMSec = '60'
        this.timeUnitIHB = 'Sec'
      }

      if (this.values.syncSettingsDelayInMSec !== null) {
        const unitResSSD = await predictBestTimeUnitFromMsValue(
          this.values.syncSettingsDelayInMSec
        )

        this.timeUnitSSD = unitResSSD.unit
        this.tempSyncSettingsDelayInMSec = unitResSSD.value
      } else {
        this.tempSyncSettingsDelayInMSec = '60'
        this.timeUnitSSD = 'Sec'
      }

      if (this.values.userRegisterTokenTtlInMSec !== null) {
        const unitResURTT = await predictBestTimeUnitFromMsValue(
          this.values.userRegisterTokenTtlInMSec
        )

        this.timeUnitURTT = unitResURTT.unit
        this.tempUserRegisterTokenTtlInMSec = unitResURTT.value
      } else {
        this.tempUserRegisterTokenTtlInMSec = '60'
        this.timeUnitURTT = 'Sec'
      }

      if (!this.values.systemSchedulerPoolSize) {
        this.values.systemSchedulerPoolSize = '10'
      }

      if (this.values.credentials.inactiveTtlInMSec !== null) {
        const unitResITT = await predictBestTimeUnitFromMsValue(
          this.values.credentials.inactiveTtlInMSec
        )

        this.timeUnitITT = unitResITT.unit
        this.tempInactiveTtlInMSec = unitResITT.value
      } else {
        this.tempInactiveTtlInMSec = '60'
        this.timeUnitITT = 'Sec'
      }

      if (this.values.credentials.syncDelayInMSec !== null) {
        const unitResSD = await predictBestTimeUnitFromMsValue(
          this.values.credentials.syncDelayInMSec
        )

        this.timeUnitSD = unitResSD.unit
        this.tempSyncDelayInMSec = unitResSD.value
      } else {
        this.tempSyncDelayInMSec = '60'
        this.timeUnitSD = 'Sec'
      }

      if (this.values.groovyScript.inactiveTtlInMSec !== null) {
        const unitResGSITT = await predictBestTimeUnitFromMsValue(
          this.values.groovyScript.inactiveTtlInMSec
        )

        this.timeUnitGSITT = unitResGSITT.unit
        this.tempGroovyScriptInactiveTtlInMSec = unitResGSITT.value
      } else {
        this.tempGroovyScriptInactiveTtlInMSec = '60'
        this.timeUnitGSITT = 'Sec'
      }

      if (this.values.groovyScript.cleanUpDelayInMSec !== null) {
        const unitResGSCUD = await predictBestTimeUnitFromMsValue(
          this.values.groovyScript.cleanUpDelayInMSec
        )

        this.timeUnitGSCUD = unitResGSCUD.unit
        this.tempGroovyScriptCleanUpDelayInMSec = unitResGSCUD.value
      } else {
        this.tempGroovyScriptCleanUpDelayInMSec = '60'
        this.timeUnitGSCUD = 'Sec'
      }

      if (this.values.internalJob.timeoutInMSec !== null) {
        const unitResIJT = await predictBestTimeUnitFromMsValue(
          this.values.internalJob.timeoutInMSec
        )

        this.timeUnitIJT = unitResIJT.unit
        this.tempInternalJobTimeoutInMSec = unitResIJT.value
      } else {
        this.tempInternalJobTimeoutInMSec = '60'
        this.timeUnitIJT = 'Sec'
      }

      if (this.values.internalJob.heartbeatInMSec !== null) {
        const unitResIJHB = await predictBestTimeUnitFromMsValue(
          this.values.internalJob.heartbeatInMSec
        )

        this.timeUnitIJHB = unitResIJHB.unit
        this.tempInternalJobHeartbeatInMSec = unitResIJHB.value
      } else {
        this.tempInternalJobHeartbeatInMSec = '60'
        this.timeUnitIJHB = 'Sec'
      }

      if (this.values.library.syncDelayInMSec !== null) {
        const unitResLSD = await predictBestTimeUnitFromMsValue(
          this.values.library.syncDelayInMSec
        )

        this.timeUnitLSD = unitResLSD.unit
        this.tempLibrarySyncDelayInMSec = unitResLSD.value
      } else {
        this.tempLibrarySyncDelayInMSec = '60'
        this.timeUnitLSD = 'Sec'
      }

      if (this.values.plugin.syncDelayInMSec !== null) {
        const unitResPSD = await predictBestTimeUnitFromMsValue(
          this.values.plugin.syncDelayInMSec
        )

        this.timeUnitPSD = unitResPSD.unit
        this.tempPluginSyncDelayInMSec = unitResPSD.value
      } else {
        this.tempPluginSyncDelayInMSec = '60'
        this.timeUnitPSD = 'Sec'
      }

      if (this.values.processExecution.heartbeatInMSec !== null) {
        const unitResPEHB = await predictBestTimeUnitFromMsValue(
          this.values.processExecution.heartbeatInMSec
        )

        this.timeUnitPEHB = unitResPEHB.unit
        this.tempProcessExecutionHeartbeatInMSec = unitResPEHB.value
      } else {
        this.tempProcessExecutionHeartbeatInMSec = '60'
        this.timeUnitPEHB = 'Sec'
      }

      if (this.values.processExecution.fetchedTtlInMSec !== null) {
        const unitResPEFT = await predictBestTimeUnitFromMsValue(
          this.values.processExecution.fetchedTtlInMSec
        )

        this.timeUnitPEFT = unitResPEFT.unit
        this.tempProcessExecutionFetchedTtlInMSec = unitResPEFT.value
      } else {
        this.tempProcessExecutionFetchedTtlInMSec = '60'
        this.timeUnitPEFT = 'Sec'
      }

      if (this.values.processExecution.cleanUpLogsDelayInMSec !== null) {
        const unitResPECUD = await predictBestTimeUnitFromMsValue(
          this.values.processExecution.cleanUpLogsDelayInMSec
        )

        this.timeUnitPECUD = unitResPECUD.unit
        this.tempProcessExecutionCleanUpLogsDelayInMSec = unitResPECUD.value
      } else {
        this.tempProcessExecutionCleanUpLogsDelayInMSec = '60'
        this.timeUnitPECUD = 'Sec'
      }

      if (this.values.processExecution.fetchEventsDelayInMSec !== null) {
        const unitResPEFED = await predictBestTimeUnitFromMsValue(
          this.values.processExecution.fetchEventsDelayInMSec
        )

        this.timeUnitPEFED = unitResPEFED.unit
        this.tempProcessExecutionFetchEventsDelayInMSec = unitResPEFED.value
      } else {
        this.tempProcessExecutionFetchEventsDelayInMSec = '60'
        this.timeUnitPEFED = 'Sec'
      }

      if (this.values.processExecution.cleanUpErrorsDelayInMSec !== null) {
        const unitResPECUED = await predictBestTimeUnitFromMsValue(
          this.values.processExecution.cleanUpErrorsDelayInMSec
        )

        this.timeUnitPECUED = unitResPECUED.unit
        this.tempProcessExecutionCleanUpErrorsDelayInMSec = unitResPECUED.value
      } else {
        this.tempProcessExecutionCleanUpErrorsDelayInMSec = '60'
        this.timeUnitPECUED = 'Sec'
      }

      if (this.values.processExecution.fixExecutionStateDelayInMSec !== null) {
        const unitResPEFESD = await predictBestTimeUnitFromMsValue(
          this.values.processExecution.fixExecutionStateDelayInMSec
        )

        this.timeUnitPEFESD = unitResPEFESD.unit
        this.tempProcessExecutionFixExecutionStateDelayInMSec = unitResPEFESD.value
      } else {
        this.tempProcessExecutionFixExecutionStateDelayInMSec = '60'
        this.timeUnitPEFESD = 'Sec'
      }

      if (this.values.processExecution.executeProcessStepChildEventDelayInMSec !== null) {
        const unitResPEEPSCE = await predictBestTimeUnitFromMsValue(
          this.values.processExecution.executeProcessStepChildEventDelayInMSec
        )

        this.timeUnitPEEPSCE = unitResPEEPSCE.unit
        this.tempProcessExecutionExecuteProcessStepChildEventDelayInMSec = unitResPEEPSCE.value
      } else {
        this.tempProcessExecutionExecuteProcessStepChildEventDelayInMSec = '60'
        this.timeUnitPEEPSCE = 'Sec'
      }

      if (!this.values.processExecution.cleanUpBatchSize) {
        this.values.processExecution.cleanUpBatchSize = '10000'
      }

      if (this.values.restTemplate.inactiveTtlInMSec !== null) {
        const unitResRTITT = await predictBestTimeUnitFromMsValue(
          this.values.restTemplate.inactiveTtlInMSec
        )

        this.timeUnitRTITT = unitResRTITT.unit
        this.tempRestTemplateInactiveTtlInMSec = unitResRTITT.value
      } else {
        this.tempRestTemplateInactiveTtlInMSec = '60'
        this.timeUnitRTITT = 'Sec'
      }

      if (this.values.restTemplate.cleanUpDelayInMSec !== null) {
        const unitResRTCUD = await predictBestTimeUnitFromMsValue(
          this.values.restTemplate.cleanUpDelayInMSec
        )

        this.timeUnitRTCUD = unitResRTCUD.unit
        this.tempRestTemplateCleanUpDelayInMSec = unitResRTCUD.value
      } else {
        this.tempRestTemplateCleanUpDelayInMSec = '60'
        this.timeUnitRTCUD = 'Sec'
      }

      if (this.values.triggerCron.syncDelayInMSec !== null) {
        const unitResTCS = await predictBestTimeUnitFromMsValue(
          this.values.triggerCron.syncDelayInMSec
        )

        this.timeUnitTCS = unitResTCS.unit
        this.tempTriggerCronSyncDelayInMSec = unitResTCS.value
      } else {
        this.tempTriggerCronSyncDelayInMSec = '60'
        this.timeUnitTCS = 'Sec'
      }

      if (this.values.triggerRest.syncDelayInMSec !== null) {
        const unitResTRS = await predictBestTimeUnitFromMsValue(
          this.values.triggerRest.syncDelayInMSec
        )

        this.timeUnitTRS = unitResTRS.unit
        this.tempTriggerRestSyncDelayInMSec = unitResTRS.value
      } else {
        this.tempTriggerRestSyncDelayInMSec = '60'
        this.timeUnitTRS = 'Sec'
      }

      if (this.values.triggerRest.waitEventTimeInMSec !== null) {
        const unitResTRWET = await predictBestTimeUnitFromMsValue(
          this.values.triggerRest.waitEventTimeInMSec
        )

        this.timeUnitTRWET = unitResTRWET.unit
        this.tempTriggerRestWaitEventTimeInMSec = unitResTRWET.value
      } else {
        this.tempTriggerRestWaitEventTimeInMSec = '60'
        this.timeUnitTRWET = 'Sec'
      }

      if (this.values.triggerMessaging.syncDelayInMSec !== null) {
        const unitResTMS = await predictBestTimeUnitFromMsValue(
          this.values.triggerMessaging.syncDelayInMSec
        )

        this.timeUnitTMS = unitResTMS.unit
        this.tempTriggerMessagingSyncDelayInMSec = unitResTMS.value
      } else {
        this.tempTriggerMessagingSyncDelayInMSec = '60'
        this.timeUnitTMS = 'Sec'
      }

      if (this.values.triggerEventHandler.syncDelayInMSec !== null) {
        const unitResTHS = await predictBestTimeUnitFromMsValue(
          this.values.triggerEventHandler.syncDelayInMSec
        )

        this.timeUnitTHS = unitResTHS.unit
        this.tempTriggerEventHandlerSyncDelayInMSec = unitResTHS.value
      } else {
        this.tempTriggerEventHandlerSyncDelayInMSec = '60'
        this.timeUnitTHS = 'Sec'
      }

      if (this.values.triggerEventHandler.handleDelayInMSec !== null) {
        const unitResTHH = await predictBestTimeUnitFromMsValue(
          this.values.triggerEventHandler.handleDelayInMSec
        )

        this.timeUnitTHH = unitResTHH.unit
        this.tempTriggerEventHandlerHandleDelayInMSec = unitResTHH.value
      } else {
        this.tempTriggerEventHandlerHandleDelayInMSec = '60'
        this.timeUnitTHH = 'Sec'
      }

      if (!this.values.triggerEventHandler.eventsCount) {
        this.values.triggerEventHandler.eventsCount = '100'
      }

      if (this.values.storage.cleanUpDelayInMSec !== null) {
        const unitResSCUD = await predictBestTimeUnitFromMsValue(
          this.values.storage.cleanUpDelayInMSec
        )

        this.timeUnitSCUD = unitResSCUD.unit
        this.tempStorageCleanUpDelayInMSec = unitResSCUD.value
      } else {
        this.tempStorageCleanUpDelayInMSec = '60'
        this.timeUnitSCUD = 'Sec'
      }

      if (!this.values.security.prometheusMetricsWhitelist) {
        this.values.security.prometheusMetricsWhitelist = []
      }

      setTimeout(() => this.lock = false, 100)

    } else if (this.type === 'ADMIN') {
      if (this.values.instanceHeartbeatInMSec !== null) {
        const unitResIHB = await predictBestTimeUnitFromMsValue(
          this.values.instanceHeartbeatInMSec
        )

        this.timeUnitIHB = unitResIHB.unit
        this.tempInstanceHeartbeatInMSec = unitResIHB.value
      } else {
        this.tempInstanceHeartbeatInMSec = '60'
        this.timeUnitIHB = 'Sec'
      }

      if (this.values.syncSettingsDelayInMSec !== null) {
        const unitResSSD = await predictBestTimeUnitFromMsValue(
          this.values.syncSettingsDelayInMSec
        )

        this.timeUnitSSD = unitResSSD.unit
        this.tempSyncSettingsDelayInMSec = unitResSSD.value
      } else {
        this.tempSyncSettingsDelayInMSec = '60'
        this.timeUnitSSD = 'Sec'
      }

      if (this.values.userRegisterTokenTtlInMSec !== null) {
        const unitResURTT = await predictBestTimeUnitFromMsValue(
          this.values.userRegisterTokenTtlInMSec
        )

        this.timeUnitURTT = unitResURTT.unit
        this.tempUserRegisterTokenTtlInMSec = unitResURTT.value
      } else {
        this.tempUserRegisterTokenTtlInMSec = '60'
        this.timeUnitURTT = 'Sec'
      }

      if (!this.values.systemSchedulerPoolSize) {
        this.values.systemSchedulerPoolSize = '10'
      }

      if (!this.values.moduleVersion) {
        this.values.moduleVersion = new Date().getFullYear() + '.00'
      }

      if (this.values.captcha.ttlInMSec !== null) {
        const unitResCT = await predictBestTimeUnitFromMsValue(
          this.values.captcha.ttlInMSec
        )

        this.timeUnitCT = unitResCT.unit
        this.tempCaptchaTtlInMSec = unitResCT.value
      } else {
        this.tempCaptchaTtlInMSec = '60'
        this.timeUnitCT = 'Sec'
      }

      if (this.values.captcha.cleanDelayInMSec !== null) {
        const unitResCCD = await predictBestTimeUnitFromMsValue(
          this.values.captcha.cleanDelayInMSec
        )

        this.timeUnitCCD = unitResCCD.unit
        this.tempCaptchaCleanDelayInMSec = unitResCCD.value
      } else {
        this.tempCaptchaCleanDelayInMSec = '60'
        this.timeUnitCCD = 'Sec'
      }

      if (this.values.history.ttlInMSec !== null) {
        const unitResHT = await predictBestTimeUnitFromMsValue(
          this.values.history.ttlInMSec
        )

        this.timeUnitHT = unitResHT.unit
        this.tempHistoryTtlInMSec = unitResHT.value
      } else {
        this.tempHistoryTtlInMSec = '60'
        this.timeUnitHT = 'Sec'
      }

      if (this.values.history.cleanDelayInMSec !== null) {
        const unitResHCD = await predictBestTimeUnitFromMsValue(
          this.values.history.cleanDelayInMSec
        )

        this.timeUnitHCD = unitResHCD.unit
        this.tempHistoryCleanDelayInMSec = unitResHCD.value
      } else {
        this.tempHistoryCleanDelayInMSec = '60'
        this.timeUnitHCD = 'Sec'
      }

      // Internal job
      if (this.values.internalJob.timeoutInMSec !== null) {
        const unitResIJT = await predictBestTimeUnitFromMsValue(
          this.values.internalJob.timeoutInMSec
        )

        this.timeUnitIJT = unitResIJT.unit
        this.tempInternalJobTimeoutInMSec = unitResIJT.value
      } else {
        this.tempInternalJobTimeoutInMSec = '60'
        this.timeUnitIJT = 'Sec'
      }

      if (this.values.internalJob.heartbeatInMSec !== null) {
        const unitResIJHB = await predictBestTimeUnitFromMsValue(
          this.values.internalJob.heartbeatInMSec
        )

        this.timeUnitIJHB = unitResIJHB.unit
        this.tempInternalJobHeartbeatInMSec = unitResIJHB.value
      } else {
        this.tempInternalJobHeartbeatInMSec = '60'
        this.timeUnitIJHB = 'Sec'
      }

      if (!this.values.security.prometheusMetricsWhitelist) {
        this.values.security.prometheusMetricsWhitelist = []
      }

    } else {
      setTimeout(() => this.lock = false, 100)
    }
  },
  methods: {
    validURL(str) {
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

      return !!pattern.test(str)
    },
    formatValues() {
      const value = this.values

      switch (this.type) {
      case 'PROCESSING':
        return {
          maxProcessingDurationInMSec: value.maxProcessingDurationInMSec,
          instanceHeartbeatInMSec: value.instanceHeartbeatInMSec,
          syncSettingsDelayInMSec: value.syncSettingsDelayInMSec,
          userRegisterTokenTtlInMSec: value.userRegisterTokenTtlInMSec,
          systemSchedulerPoolSize: value.systemSchedulerPoolSize,
          credentials: {
            inactiveTtlInMSec: value.credentials.inactiveTtlInMSec,
            syncDelayInMSec: value.credentials.syncDelayInMSec
          },
          internalJob: {
            timeoutInMSec: value.internalJob.timeoutInMSec,
            heartbeatInMSec: value.internalJob.heartbeatInMSec
          },
          triggerCron: {
            syncDelayInMSec: value.triggerCron.syncDelayInMSec
          },
          triggerRest: {
            syncDelayInMSec: value.triggerRest.syncDelayInMSec,
            waitEventTimeInMSec: value.triggerRest.waitEventTimeInMSec
          },
          groovyScript: {
            inactiveTtlInMSec: value.groovyScript.inactiveTtlInMSec,
            cleanUpDelayInMSec: value.groovyScript.cleanUpDelayInMSec
          },
          restTemplate: {
            inactiveTtlInMSec: value.restTemplate.inactiveTtlInMSec,
            cleanUpDelayInMSec: value.restTemplate.cleanUpDelayInMSec
          },
          processExecution: {
            heartbeatInMSec: value.processExecution.heartbeatInMSec,
            fetchedTtlInMSec: value.processExecution.fetchedTtlInMSec,
            cleanUpLogsDelayInMSec: value.processExecution.cleanUpLogsDelayInMSec,
            fetchEventsDelayInMSec: value.processExecution.fetchEventsDelayInMSec,
            cleanUpErrorsDelayInMSec: value.processExecution.cleanUpErrorsDelayInMSec,
            fixExecutionStateDelayInMSec: value.processExecution.fixExecutionStateDelayInMSec,
            executeProcessStepChildEventDelayInMSec: value.processExecution.executeProcessStepChildEventDelayInMSec,
            cleanUpBatchSize: value.processExecution.cleanUpBatchSize
          },
          triggerMessaging: {
            syncDelayInMSec: value.triggerMessaging.syncDelayInMSec
          },
          triggerEventHandler: {
            eventsCount: value.triggerEventHandler.eventsCount,
            syncDelayInMSec: value.triggerEventHandler.syncDelayInMSec,
            handleDelayInMSec: value.triggerEventHandler.handleDelayInMSec
          },
          storage: {
            cleanUpDelayInMSec: value.storage.cleanUpDelayInMSec
          },
          plugin: {
            syncDelayInMSec: value.plugin.syncDelayInMSec
          },
          library: {
            syncDelayInMSec: value.library.syncDelayInMSec
          },
          security: {
            prometheusMetricsWhitelist: value.security.prometheusMetricsWhitelist
          }
        }
      case 'FRONTEND':
        return {
          defaultLocale: value.defaultLocale,
          baseUrlRest: value.baseUrlRest,
          logServerUrl: value.logServerUrl,
          metricServerUrl: value.metricServerUrl,
          setPasswordUrl: value.setPasswordUrl,
          visualisationServerUrl: value.visualisationServerUrl,
          environmentName: value.environmentName
        }
      case 'ADMIN':
        return {
          captcha: {
            ttlInMSec: value.captcha.ttlInMSec,
            cleanDelayInMSec: value.captcha.cleanDelayInMSec
          },
          history: {
            ttlInMSec: value.history.ttlInMSec,
            cleanEnabled: value.history.cleanEnabled,
            cleanDelayInMSec: value.history.cleanDelayInMSec
          },
          security: {
            prometheusMetricsWhitelist: value.security.prometheusMetricsWhitelist
          },
          internalJob: {
            timeoutInMSec: value.internalJob.timeoutInMSec,
            heartbeatInMSec: value.internalJob.heartbeatInMSec
          },
          moduleVersion: value.moduleVersion,
          instanceHeartbeatInMSec: value.instanceHeartbeatInMSec,
          syncSettingsDelayInMSec: value.syncSettingsDelayInMSec,
          systemSchedulerPoolSize: value.systemSchedulerPoolSize,
          userRegisterTokenTtlInMSec: value.userRegisterTokenTtlInMSec
        }
      default:
        return {}
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
