<template>
  <v-container fluid class="custom-container-details">
    <div class="fill-height pr-2">
      <v-row wrap no-gutters class=" fill-height ">
        <v-col
          cols="12"
          class="fill-height"
        >
          <v-card class="pa-1 fill-height custom-bg ">
            <v-form
              ref="form"
              v-model="valid"
              class="pt-3"
            >
              <v-row wrap no-gutters justify="space-between" class="pb-4">
                <v-col
                  cols="12"
                >
                  <h3>{{ setting && setting.type ? $lang.status[setting.type] : '' }}</h3>
                  <p class="text--secondary">{{ formatHint }}</p>
                </v-col>
                <v-col
                  cols="12"
                >
                  <values
                    v-if="!initLoading"
                    :type="setting.type"
                    :is-edit="isEdit"
                    :can-edit="userCanEdit"
                    :data="setting.values"
                    @setValues="setting.values = $event"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters wrap justify="space-between">
                <v-divider class="mb-4" />
              </v-row>
              <v-row no-gutters wrap justify="space-between">
                <v-col
                  cols="6"
                  class="text-left"
                >
                  <div class="d-inline-flex align-center">
                    <v-btn
                      outlined
                      color="primary"
                      :to="{ name: 'globalSettings', params: { lang: $lang.current_lang } }"
                    >
                      <v-icon
                        left
                        dark
                      >
                        mdi-arrow-left
                      </v-icon>
                      <span class="ml-1">{{ $lang.actions.back }}</span>
                    </v-btn>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    :disabled="!isFormValid"
                    color="primary"
                    class="button-default-width ml-2"
                    @click="submit()"
                  >
                    <v-icon
                      left
                      dark
                      class="mr-1"
                    >
                      mdi mdi-floppy
                    </v-icon>
                    {{ $lang.actions.submit }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <multi-errors-snackbar
      :show="showSnackErrors"
      :errors="errorsForSnackbar"
      @close="showSnackErrors = false; errorsForSnackbar = []"
    />
  </v-container>
</template>

<script>
import {
  getGlobalSettingByIdUsingGET as getSetting,
  updateProcessingGlobalSettingUsingPUT as updateInstanceProcessing,
  updateFrontendGlobalSettingUsingPUT as updateFrontend

} from '@/utils/api'
import { definitions } from '@/utils/definitions'
import Values from './Values.vue'
import MultiErrorsSnackbar from '@/components/ui/MultiErrorsSnackbar.vue'
import { mapActions } from 'vuex'
import { bus } from '@/main'

export default {
  components: {
    MultiErrorsSnackbar,
    Values
  },
  data() {
    return {
      err: '',
      success: '',
      errorsForSnackbar: [],
      showSnackErrors: false,
      valid: false,
      isEdit: true,
      loading: false,
      initLoading: true,
      lock: false,
      setting: {
        type: '',
        id: 0,
        modifiedOn: '',
        values: null
      },
      userCanEdit: false,
      isSuperUser: false,
      types: definitions.GlobalSetting.properties.type.enum
    }
  },
  computed: {
    formatHint() {
      return `${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.setting.modifiedOn)}, ID: ${this.setting.id}`
    },
    formattedTypes() {
      return this.types.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    isFormValid() {
      return this.valid && !this.lock && this.setting?.values && this.userCanEdit && Object.keys(this.setting.values).length > 0
    }
  },
  watch: {
    isFormValid(val) {
      this.formValid(val)
    }
  },
  mounted() {
    bus.$on('saveResource', this.submit)
  },
  beforeDestroy() {
    bus.$off('saveResource', this.submit)
  },
  created() {
    this.isEdit = this.$route.name === 'settingEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
    }

    if (this.$route.params.id) {
      this.loading = true
      getSetting({ id: this.$route.params.id })
        .then((res) => {
          this.setting = res.data.data

          this.userCanEdit = true

          this.loading = false
          this.initLoading = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar', 'formValid']),

    async submit() {
      this.lock = true

      let res = null

      try {

        switch (this.setting.type) {
        case 'PROCESSING':
          res = await updateInstanceProcessing({ body: this.setting })
          break
        case 'ADMIN':
          // TBD
          break
        case 'FRONTEND':
          res = await updateFrontend({ body: this.setting })
          break
        default:
          res = null
        }

        if (res && res.status !== 200) {
          if (res?.response?.data?.data?.[0]?.error) {

            res?.response?.data?.data?.forEach((error) => {
              this.errorsForSnackbar.push({
                text: error.error,
                value: null
              })
            })
            this.showSnackErrors = true
          } else {
            const errorMessage = this.isEdit ? this.$lang.errors.settingUpdate : this.$lang.errors.settingCreate

            this.addSnackbar({
              message: errorMessage,
              timeout: 5000,
              color: 'error'
            })
          }
          this.lock = false

          return
        }
        this.addSnackbar({
          message: this.$lang.success.settingUpdated,
          timeout: 5000,
          color: 'success'
        })
        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    }
  }
}
</script>

<style>
.copy-name-icon .v-icon{
  color: #009fb7;
}
</style>
